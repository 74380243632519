
import { Vue, Component } from 'vue-property-decorator'
import DatePicker from '@/components/date-picker.vue'
import { apiUserEquityList } from '@/api/admin'
import { RequestPaging } from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'

@Component({
  components: { lsPagination, DatePicker }
})
export default class EquityList extends Vue {
  recordData = []
  equityDetail = []
  searchObj: any = {
    mobile: '',
    start_time: '',
    end_time: '',
    remark: '',
    type: ''
  };

  pager: RequestPaging = new RequestPaging();

  async getLists () {
    const ret = await this.pager.request({
      callback: apiUserEquityList,
      params: {
        ...this.searchObj,
        mobile: this.$route.query.mobile
      }
    })
    this.recordData = ret.list
    this.equityDetail = ret.detail
  }

  async reset () {
    Object.keys(this.searchObj).map((key) => {
      if (key === 'id') return
      this.$set(this.searchObj, key, '')
    })
    this.getLists()
  }

  created () {
    this.getLists()
  }
}
